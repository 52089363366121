import { createContext } from 'react';

// each payment type will have an ID and a name, both will be strings
export interface IPaymentTenderType {
    tenderName: string;
    tenderTypeId: string;
}

// stores the payment modules element ID as a key and an array of Payment Tender Types for each tender type ID and name the module is associated with
interface IPaymentContextState {
    paymentModules: Record<string, IPaymentTenderType[]>;
    selectedPayment: IPaymentTenderType;
}

interface IPaymentContext extends IPaymentContextState {
    registerPaymentMethod: (elementId: string, tenderTypeList: IPaymentTenderType[]) => void;
    setSelectedPaymentState: (paymentTenderType: IPaymentTenderType) => void;
}

const _errorMsg = 'Must be used within a provider';

const paymentModules: Record<string, IPaymentTenderType[]> = {};
const selectedPayment: IPaymentTenderType = {} as IPaymentTenderType;
const registerPaymentMethod = (elementId: string, tenderTypeList: IPaymentTenderType[]) => {
    throw Error(_errorMsg);
};
const setSelectedPaymentState = (paymentTenderType: IPaymentTenderType) => {
    throw Error(_errorMsg);
};

export const PaymentContext = createContext<IPaymentContext>({
    paymentModules,
    selectedPayment,
    registerPaymentMethod,
    setSelectedPaymentState
});

const { Consumer: PaymentConsumer, Provider: PaymentProvider } = PaymentContext;
export { PaymentConsumer, PaymentProvider };
